export const getSearchParams = () => {
  let searchParams = new URLSearchParams(window.location.search)
  let params = {}
  for (let [key, value] of searchParams.entries()) {
    params[key] = value
  }
  return params
}

export const formatPrice = (number, lang = 'pt-BR', currency = 'BRL') => new Intl.NumberFormat(lang, {
  style: 'currency',
  currency
}).format(number)

export const getCurrencySymbol = ( lang = 'pt-BR', currency = 'BRL') =>  new Intl.NumberFormat(lang, {
  style: 'currency',
  currency,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
}).format(0).replace(/\d/g, '').trim()
