import { Component } from 'preact';
import PropTypes from 'prop-types';
import handleInputChange from '%/utils/handleInputChange';
import Detash from '../DetailTabs/Detash';
import CustomSelect from '../CustomSelect';
import { setSelectedVersion } from '../../stores/Subscription';

export default class SubscriptionDetailTabs extends Component {
  constructor(props) {
    super(props);

    // A chave `labels` só serve para guardar valores já convertidos para "id",
    // melhorando a velocidade da renderização.
    this.memo = {
      labels: {},
    };

    this.listTabs = [
      'Especificações',
      'Conforto',
      'Segurança',
      'Conectividade',
    ];

    this.state = {
      selectedTab: null,
      content: [],
      activeVersion: this.props.versions[0] || {},
    };

    this.labelToId = this.labelToId.bind(this);
    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleVersionChange = this.handleVersionChange.bind(this);
    this.getSelectedVersion = this.getSelectedVersion.bind(this);

    this.setContent();
    this.watchStore();
  }

  handleVersionChange(e) {
    this.handleInputChange(e);

    const {
      target: { value: slug },
    } = e;
    const { versions } = this.props;

    const activeVersion = versions.find(item => item.slug === slug);

    this.setState({ activeVersion });
    this.setContent();
    setSelectedVersion(activeVersion);
  }

  /**
   * Gerencia a exibição de conteúdo da tab e seu estado.
   * @param {Event} e
   * @param {string} selectedTab O label da tab selecionada
   */
  handleSelectTab(e, selectedTab) {
    e.preventDefault();

    this.setState({
      selectedTab,
    });
  }

  setContent() {
    const { activeVersion } = this.state;
    this.setState(
      previousState => ({
        selectedTab: previousState.selectedTab || this.listTabs[0],
        content: [
          activeVersion.detail_list,
          activeVersion.comfort_list,
          activeVersion.item_list,
          activeVersion.connectivity_list,
        ],
        selectedImage:
          activeVersion.image || activeVersion.item_image.middle_image,
        selectedTitle: `${activeVersion.title} ${activeVersion.subtitle}`,
      }),
      () => {
        $('.tabs__link').first().trigger('click');
      },
    );
  }

  getSelectedVersion() {
    return this.state.activeVersion;
  }

  watchStore() {
    if (!window.store) return;

    window.store.Subscription.watch(({ selectedVersion }) => {
      if (!selectedVersion) return;
      this.setState({
        activeVersion: selectedVersion,
      });
      this.setContent();
    });
  }

  versionOptionsToCustomSelect() {
    return this.props.versions.map(version => ({
      value: version.slug,
      label: version.title,
    }));
  }

  /**
   * Indica se a listagem é de detalhes. Util para verificar quando devemos
   * inserir bullets na listagem.
   * @param {string[]} categoryItems
   * @return {bool}
   */
  isDetailsList(categoryItems) {
    return categoryItems.reduce((acc, cur) => acc && cur.includes(':'), true);
  }

  /**
   * Converte o conteúdo de um item em texto. Útil para renderizar de forma
   * diferente baseada na estrutura de `item`.
   * @returns {string}
   */
  itemToText(item) {
    if (item.includes(':')) {
      const formattedItem = item.split(':');
      return (
        <span>
          <strong>{formattedItem[0]}</strong>: {formattedItem[1]}
        </span>
      );
    }

    return item;
  }

  /**
   * Converte uma label para um ID, removendo acentuação e convertendo a fonte
   * para lowecase.
   * @param {string} label
   */
  labelToId(label) {
    if (!label) return false;

    if (this.memo.labels[label]) {
      return this.memo.labels[label];
    }

    this.memo.labels[label] = label
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '-');
    return this.labelToId(label);
  }

  /**
   * Renderiza os paineis de conteúdo das tabs
   */
  renderContentPanels() {
    const panels = this.state.content.map((category, index) => (
      <div
        key={`panel-${this.labelToId(this.listTabs[index])}`}
        id={this.labelToId(this.listTabs[index])}
        className={`tab-pane tabs__content ${
          this.state.selectedTab === this.listTabs[index] && 'active'
        }`}
      >
        <ul
          className={`list list--specs-items ${
            this.isDetailsList(category) ? 'list--no-style' : 'list--primary'
          }`}
        >
          {category.length > 0 &&
            category.map(item => <li>{this.itemToText(item)}</li>)}
        </ul>
      </div>
    ));
    return <div>{panels}</div>;
  }

  /**
   * Renderiza a lista com as tabs
   */
  renderTabs() {
    const { content } = this.state;
    const items = this.listTabs.map((item, index) => {
      if (content[index] && content[index].length > 0) {
        return (
          <li key={this.labelToId(item)} className="tabs__item">
            <a
              onClick={e => this.handleSelectTab(e, item)}
              className={`tabs__link ${
                this.state.selectedTab === item && 'active'
              }`}
              href={`#${this.labelToId(item)}`}
            >
              {item}
            </a>
          </li>
        );
      }

      return null;
    });
    return <ul className="nav tabs__nav">{items}</ul>;
  }

  render() {
    const { detash, showImage, shouldShowVersionSelect } = this.props;
    const { selectedImage, selectedTitle } = this.state;
    return (
      <div className="detail-tabs">
        <div className="card-panel card-panel--into-space">
          {detash}
          {showImage && (
            <div className="detail-tabs__image">
              {shouldShowVersionSelect ? (
                <CustomSelect
                  id="select-version"
                  name="selectedVersion"
                  handleSelectChange={this.handleVersionChange}
                  options={this.versionOptionsToCustomSelect()}
                  value={this.getSelectedVersion().slug}
                  placeholder={false}
                  removeItemButton={false}
                />
              ) : (
                <strong className="detail-tabs__title">{selectedTitle}</strong>
              )}
              <img src={selectedImage} alt={selectedTitle} />
            </div>
          )}
          <div className="tabs">
            {this.renderTabs()}
            {this.renderContentPanels()}
          </div>
        </div>
      </div>
    );
  }
}

SubscriptionDetailTabs.Detash = Detash;

SubscriptionDetailTabs.defaultProps = {
  showImage: false,
  versions: [],
  detash: null,
  shouldShowVersionSelect: null,
};

SubscriptionDetailTabs.propTypes = {
  detash: PropTypes.node,
  showImage: PropTypes.bool,
  shouldShowVersionSelect: PropTypes.bool,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      periods: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          plans: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              price: PropTypes.string,
              price_float: PropTypes.number,
            }),
          ),
        }),
      ),
    }),
  ),
};
