import fetchCached from './fetchCached'

/**
 * Service for Subscription Portal API
 */
class AutoServiceSubscriptionsApi {

  /**
   * Endpoint no contexto de Portal
   *
   * @param {string} suffixEndpoint Sufixo do endpoint da request
   * @returns {string} Retorna o endpoint completo
   */
  getPortalEndpoint(suffixEndpoint) {
    const portal = document.querySelector("meta[name='auto_api_channel']")
      .content

    return `/portals/${portal}${suffixEndpoint}`
  }

  /**
   * Return all available filters for plans of the subscription
   * @return {Promise}
   */
  getSubscriptionsFilters(skipCache = false) {
    return fetchCached(this.getPortalEndpoint('/subscriptions/plans_filters'), [], 30, skipCache);
  }

  /**
   * Return all available plans for subscription
   * @return {Promise}
   */
  getSubscriptions(customParams = [],  skipCache = false) {
    return fetchCached(this.getPortalEndpoint('/subscriptions/plans'), customParams, 30, skipCache);
  }
}

export default new AutoServiceSubscriptionsApi()
