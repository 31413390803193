import {createEvent, createStore} from 'effector';

export const setSelectedVersion = createEvent('set selected version');
export const setSelectedPeriod = createEvent('set selected period');
export const setSelectedPlan = createEvent('set selected plan');
export const setVersions = createEvent('set all available versions');

const Subscription = (function() {
  let instance;

  return function(
    defaultValue = {
      selectedVersion: null,
      selectedPeriod: null,
      selectedPlan: null,
      versions: []
    }
  ) {
    if (!instance) {
      instance = createStore(defaultValue);

      instance.on(setSelectedVersion, (state, selectedVersion) => ({
        ...state,
        selectedVersion
      }));

      instance.on(setSelectedPeriod, (state, selectedPeriod) => ({
        ...state,
        selectedPeriod
      }));

      instance.on(setSelectedPlan, (state, selectedPlan) => ({
        ...state,
        selectedPlan
      }));

      instance.on(setVersions, (state, versions) => ({
        ...state,
        versions
      }));
    }

    return instance;
  };
})();

export default Subscription;
