import { h, Component } from 'preact'
import classNames from 'classnames'
import PropTypes from 'prop-types'

class DropdownMenu extends Component {

  render() {
    const {children, className } = this.props

    return (
      <ul className={classNames("dropdown-filter__list", className)}>
        {children}
      </ul>
    )
  }
}

DropdownMenu.defaultProps = {
}

DropdownMenu.propTypes = {
}


class DropdownMenuItem extends Component {

  render() {
    const {children, className, header } = this.props

    return (
      <li className={classNames("dropdown-filter__list-item", className, {
        'dropdown-filter__list-item--header': header,
      })}>{children}</li>
    )
  }
}

DropdownMenuItem.defaultProps = {
  header: false,
}

DropdownMenuItem.propTypes = {
  header: PropTypes.bool
}

export {DropdownMenuItem, DropdownMenu}
