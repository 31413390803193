// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';

import IMask from 'imask';
import { cnpjMasks } from '%/utils/masks';

export default class InputCnpj extends Component {
  componentDidMount() {
    const { updateState } = this.props;

    const cnpjMask = IMask(this.input, {
      mask: [...cnpjMasks],
    });

    cnpjMask.on('accept', () => {
      if (updateState) {
        updateState(cnpjMask.value);
      }
    });

    if (updateState) {
      updateState(cnpjMask.value);
    }
  }

  render() {
    const { handleChange, value, name, placeholder, label, shouldValidate } =
      this.props;

    return (
      <div className="form-group">
        {label && <label className="control-label">{label}</label>}
        <input
          // eslint-disable-next-line no-return-assign
          ref={node => (this.input = node)}
          onChange={handleChange}
          value={value}
          type="text"
          className="form-control"
          name={name}
          required
          placeholder={placeholder}
          minLength={14}
          data-bouncer-target="#invalid-cnpj"
          data-should-validate={shouldValidate}
        />
        <div id="invalid-cnpj" className="invalid-feedback" />
      </div>
    );
  }
}

InputCnpj.defaultProps = {
  handleChange() {},
  value: '',
  name: 'cnpj',
  placeholder: 'CNPJ',
  shouldValidate: false,
  updateState: () => {},
};

InputCnpj.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  shouldValidate: PropTypes.bool,
  updateState: PropTypes.func,
};
