import '../auto/global';
import './stores';
import './components';
import Carousel from '../auto/carousel';
import Choices from '../auto/choices';
import MicroModal from '../auto/micromodal';
import serviceFactory from '../auto/autoForceServiceFactory';
import serviceConversionFactory from '../auto/autoBoxOfficeServiceFactory';
import railsDateToChronometer from '../auto/utils/railsDateToChronometer';

// Criando um service que será armazenado como Singleton.
window.service = serviceFactory();
window.serviceConversion = serviceConversionFactory();

window.Carousel = Carousel;
window.Choices = Choices;
window.MicroModal = MicroModal;

window.railsDateToChronometer = railsDateToChronometer;

if (process.env.NODE_ENV === 'development') {
  // Necessário para fazer o Preact ficar disponível no React Dev Tools
  require('preact/debug');

  // Adicionando o app do guide apenas em desenvolvimento
  $(document).ready(function() {
    // require('./guide-app.js');
  });
}
