import { createStore, createEffect, createEvent } from 'effector'
import autoServiceSubscriptionsApi from '../utils/autoServiceSubscriptionsApi'


const fetchList = ({ skipCache = false } = {}) => {
  const { filter, filterArray, sort, page, per_page, active, methodApi } = window.store.SearchFilterSubscriptions.getState()
  let customParams = [
    { page },
    { per_page },
    ...filterArray
  ]

  let allFilters = { ...filter }

  if(sort.length > 0){
    customParams.push({ 'order_by': sort });
  }

  for (let key in allFilters) {
    if (allFilters[key] !== undefined && allFilters[key] !== "")
      customParams.push({ [key]: allFilters[key] })
  }

  return autoServiceSubscriptionsApi[methodApi](customParams, skipCache)
}

export const setList = createEffect('set list').use(fetchList)
export const incrementList = createEffect('increment list').use(fetchList)

export const setKeyword = createEvent('set keyword')
export const setFilter = createEvent('set filter')
export const addFilter = createEvent('add filter')
export const removeFilter = createEvent('remove filter')
export const incrementFilter = createEvent('increment filter')
export const resetList = createEvent('reset List')
export const setTotalPages = createEvent('set total_pages')
export const setTotal = createEvent('set total')
export const nextPage = createEvent('next page')
export const setPage = createEvent('set page')
export const setPerPage = createEvent('set per_page')
export const setSort = createEvent('set sort')
export const setMethodApi = createEvent('set method api')
export const setShowResults = createEvent('set show results')

let SearchFilterSubscriptions = (function () {
  let instance

  return function (defaultValue = {
    methodApi: '',
    keyword: '',
    sort: '',
    filter: {},
    filterArray: [],
    list: [],
    suggestions: [],
    loading: false,
    loadingMore: false,
    loadInitial: false,
    page: 1,
    total_pages: null,
    per_page: 12,
    total: null,
    active: true,
    showResults: false,
    showFilter: false,
    showOrder: false,
    showConversionForm: false,
    showList: true,
  }) {
    if (!instance) {
      instance = createStore(defaultValue)

      instance.on(setMethodApi, (state, methodApi) => ({
        ...state,
        methodApi
      }))

      instance.on(setShowResults, (state, showResults) => ({
        ...state,
        showResults
      }))

      instance.on(setKeyword, (state, keyword) => {
        return {
          ...state,
          keyword
        }
      })

      instance.on(setSort, (state, sort) => ({
        ...state,
        sort,
        page: 1,
      }))

      instance.on(setFilter, (state, filter) => ({
        ...state,
        filter
      }))

      instance.on(addFilter, (state, filter) => ({
        ...state,
        filter: {
          ...state.filter,
          ...filter
        }
      }))

      instance.on(removeFilter, (state, key) => {
        let filterArray = state.filterArray.filter(element => {
          for (let keyFilter in element) {
            if (keyFilter !== key) {
              return element
            }
          }
        })

        return {
          ...state,
          filterArray
        }
      })

      instance.on(incrementFilter, (state, filter) => ({
        ...state,
        filterArray: [
          ...state.filterArray,
          filter
        ]
      }))

      instance.on(nextPage, state => ({
        ...state,
        page: state.page + 1
      }))

      instance.on(setPage, (state, page) => ({
        ...state,
        page
      }))

      instance.on(setPerPage, (state, per_page) => ({
        ...state,
        per_page
      }))

      instance.on(setTotalPages, (state, total_pages) => ({
        ...state,
        total_pages
      }))

      instance.on(setTotal, (state, total) => ({
        ...state,
        total
      }))

      instance.on(resetList, (state, list = []) => ({
        ...state,
        page: 1,
        list
      }))

      // setList
      instance.on(setList.pending, (state, loading) => ({
        ...state,
        page: 1,
        loading
      }))

      instance.on(setList.fail, (state) => ({
        ...state,
        loading: false
      }))

      instance.on(setList.done, (state, res) => ({
        ...state,
        list: res.result.entries,
        total: res.result.total,
        showList: res.result.total > 0,
        showConversionForm: res.result.total === 0,
        total_pages: res.result.total_pages,
        loading: false,
        loadInitial: true
      }))

      // incrementList
      instance.on(incrementList.pending, (state, loading) => ({
        ...state,
        loading,
        loadingMore: loading
      }))

      instance.on(incrementList.done, (state, res) => ({
        ...state,
        list: [...state.list, ...res.result.entries],
        total: res.result.total,
        showList: res.result.total > 0,
        showConversionForm: res.result.total === 0,
        total_pages: res.result.total_pages,
        loading: false,
        loadingMore: false,
        loadInitial: true
      }))

      instance.on(incrementList.fail, (state) => ({
        ...state,
        loading: false,
        loadingMore: false,
        page: state.page - 1
      }))
    }

    return instance
  }
})()

export default SearchFilterSubscriptions
