import SearchFilterSubscriptions from "../auto/stores/SearchFilterSubscriptions"
import Subscription from "../auto/stores/Subscription"


// Configurando Stores
(function() {
  // Adicione chaves:valor com os stores que serão atribuídos ao escopo
  // global
  let store = {
    SearchFilterSubscriptions,
    Subscription
  };
  window.store = {};
  Object.keys(store).forEach(c => (window.store[c] = store[c]()));
})();
