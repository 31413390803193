import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import classNames from "classnames";

class DropdownToggle extends Component {

  render() {
    const {toggle, isOpen, children, className } = this.props

    return (
      <button type="button" className={classNames('dropdown-filter__toogle', className, {
        'dropdown-filter__toogle--open': isOpen
      })} onClick={toggle}>
        {children}
      </button>
    )
  }
}

DropdownToggle.defaultProps = {
  isOpen: false,
  toggle() {}
}

DropdownToggle.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

export default DropdownToggle
