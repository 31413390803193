import { Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Accordion from '../Accordion';

class AccordionVehicleWithVersion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventKey: null,
      content: [],
      detail_list: [],
      comfort_list: [],
      item_list: [],
      connectivity_list: [],
      activeVersion: this.props.versions[0] || {},
    };

    this.listToggle = [
      {
        category: 'detail_list',
        label: 'Especificações',
      },
      {
        category: 'comfort_list',
        label: 'Conforto',
      },
      {
        category: 'item_list',
        label: 'Segurança',
      },
      {
        category: 'connectivity_list',
        label: 'Conectividade',
      },
    ];

    this.handleClick = this.handleClick.bind(this);

    this.setContent();
    this.watchStore();
  }

  handleClick(eventKey) {
    this.setState({ eventKey });
  }

  setContent() {
    const { activeVersion } = this.state;
    this.setState({
      detail_list: activeVersion.detail_list,
      comfort_list: activeVersion.comfort_list,
      item_list: activeVersion.item_list,
      connectivity_list: activeVersion.connectivity_list,
    });
  }

  watchStore() {
    if (!window.store) return;

    window.store.Subscription.watch(({ selectedVersion }) => {
      if (!selectedVersion) return;
      this.setState({
        activeVersion: selectedVersion,
      });
      this.setContent();
    });
  }

  /**
   * Converte o conteúdo de um item em texto. Útil para renderizar de forma
   * diferente baseada na estrutura de `item`.
   * @returns {string}
   */
  itemToText(item) {
    if (item.includes(':')) {
      const formattedItem = item.split(':');
      return (
        <span>
          <strong>{formattedItem[0]}</strong>: {formattedItem[1]}
        </span>
      );
    }

    return item;
  }

  /**
   * Indica se a listagem é de detalhes. Util para verificar quando devemos
   * inserir bullets na listagem.
   * @param {string[]} categoryItems
   * @return {bool}
   */
  isDetailsList(categoryItems) {
    return categoryItems.reduce((acc, cur) => acc && cur.includes(':'), true);
  }

  render() {
    const { eventKey } = this.state;
    const { id } = this.props;

    return (
      <Accordion id={id} defaultActiveKey={eventKey}>
        {this.listToggle.map(
          (toggle, index) =>
            this.state[toggle.category].length > 0 && (
              <div>
                <Accordion.Toggle eventKey={index} onClick={this.handleClick}>
                  {toggle.label}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={index}>
                  <ul
                    className={classNames(
                      'list list--specs-items list--border-bottom',
                      {
                        'list--no-style': this.isDetailsList(
                          this.state[toggle.category],
                        ),
                        'list--primary': !this.isDetailsList(
                          this.state[toggle.category],
                        ),
                      },
                    )}
                  >
                    {this.state[toggle.category].map(item => (
                      <li>{this.itemToText(item)}</li>
                    ))}
                  </ul>
                </Accordion.Collapse>
              </div>
            ),
        )}
      </Accordion>
    );
  }
}

AccordionVehicleWithVersion.defaultProps = {
  id: 'accordion-vehicle',
  versions: [],
};

AccordionVehicleWithVersion.propTypes = {
  id: PropTypes.string,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      periods: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          plans: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              price: PropTypes.string,
              price_float: PropTypes.number,
            }),
          ),
        }),
      ),
    }),
  ),
};

export default AccordionVehicleWithVersion;
