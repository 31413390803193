import {h, Component} from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {addFilter} from "../../stores/SearchFilterSubscriptions";
import {formatPrice, getCurrencySymbol, getSearchParams} from "../../../portal_assinatura/utils/utils";
import queryString from "query-string";
import {setSelectedPeriod, setSelectedPlan, setSelectedVersion} from "../../stores/Subscription";

class SubscriptionPlanChoiceForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      versionSelected: "",
      periodSelected: "",
      planSelected: "",
      planSelectedObject: undefined,
      versions: [],
      periods: [],
      plans: []
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onChangeVersion = this.onChangeVersion.bind(this);
    this.onChangePeriod = this.onChangePeriod.bind(this);
    this.onChangePlan = this.onChangePlan.bind(this);
    this.applyFilters();
  }

  applyFilters() {
    const allowedFilters = ["version", "period", "plan"];

    const params = getSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (!(value && allowedFilters.includes(key))) return;

      this.setState({[`${key}Selected`]: value});
    });
  }

  componentDidMount() {
    const {versions} = this.props;

    const versionsOptions = versions.map((item) => ({
      label: item.title,
      value: item.slug,
      periods: item.periods
    }));

    this.setState({versions: versionsOptions});
  }

  onChangeVersion({target: {value}}) {
    const versionSelected = this.props.versions.find(
      (item) => item.slug === value
    );

    if (!versionSelected) return;

    const periodOptions = versionSelected.periods.map((item) => ({
      label: item.name,
      value: item.name.trim(),
      plans: item.plans
    }));

    this.setState({
      versionSelected: value,
      periods: periodOptions,
      // TODO: Ajuste o bug a partir daqui.
      planSelectedObject: undefined,
      periodSelected: '',
      planSelected: '',
    });

    setSelectedVersion(versionSelected);
  }

  onChangePeriod({target: {value}, periods}) {
    const periodSelected = periods.find((item) => item.value === value);

    if (!periodSelected) return;

    const plansOptions = periodSelected.plans.map((item) => ({
      label: item.name,
      value: item.name.trim(),
      price: item.price,
      price_float: item.price_float
    }));

    this.setState({
      periodSelected: value,
      plans: plansOptions,
      // TODO: Ajuste o bug a partir daqui.
      planSelectedObject: undefined,
      planSelected: '',
    });

    setSelectedPeriod(periodSelected);
  }

  onChangePlan({target: {value}, plans}) {
    const planSelected = plans.find((item) => item.value === value);

    if (!planSelected) return;

    this.setState({
      planSelected: planSelected.value,
      planSelectedObject: planSelected
    });

    setSelectedPlan(planSelected);
  }

  handleInputChange({target}) {
    const {periods, plans, versions} = this.state;

    const filter = target.name;
    const value = target.value;

    filter === "version" &&
    this.onChangeVersion({
      target,
      versions
    });

    filter === "period" &&
    periods.length > 0 &&
    this.onChangePeriod({target, periods});

    filter === "plan" &&
    plans.length > 0 &&
    this.onChangePlan({target, plans});

    if (!(filter && value)) return;

    const {periodSelected, planSelected, versionSelected} = this.state;

    const filterQueryString = {
      plan: planSelected,
      period: periodSelected,
      version: versionSelected
    };

    let url = new URL(window.location.pathname, window.location.origin);
    url.search = queryString.stringify(filterQueryString);

    window.history.pushState(
      "",
      "",
      `${url.origin}${url.pathname}${url.search}`
    );

  }

  render() {

    const {
      versions,
      periods,
      plans,
      versionSelected,
      periodSelected,
      planSelected,
      planSelectedObject
    } = this.state;

    return (
      <div className={"subscription-choice"}>
        <form action="" className="subscription-choice__form row">
          <div className="form-group col-md-6 col-lg-3">
            <label className={"subscription-choice__label"}>Escolha a versão:</label>
            <CustomSelect
              placeholderValue={"Escolha a versão"}
              classNames={{
                containerOuter: 'choices choices--subscription-portal'
              }}
              name="version"
              handleSelectChange={this.handleInputChange}
              options={versions}
              value={versionSelected}
              searchEnabled={false}
            />
          </div>
          <div className="form-group col-md-6 col-lg-3">
            <label className={"subscription-choice__label"}>Período de assinatura:</label>
            <CustomSelect
              placeholderValue={"Escolha o período"}
              classNames={{
                containerOuter: 'choices choices--subscription-portal'
              }}
              name="period"
              handleSelectChange={this.handleInputChange}
              options={periods}
              value={periodSelected}
              searchEnabled={false}
            />
          </div>
          <div className="form-group col-md-6 col-lg-3">
            <label className={"subscription-choice__label"}>Escolha a franquia:</label>
            <CustomSelect
              placeholderValue={"Escolha a franquia"}
              classNames={{
                containerOuter: 'choices choices--subscription-portal'
              }}
              name="plan"
              handleSelectChange={this.handleInputChange}
              options={plans}
              value={planSelected}
              searchEnabled={false}
            />
          </div>
          <div className="form-group col-md-6 col-lg-3">
            <div className={"subscription-choice__price-wrapper"}>
              <span className="subscription-choice__label subscription-choice__label--price">Custo mensal:</span>
              <span
                className="subscription-choice__price">{planSelectedObject ? formatPrice(planSelectedObject.price_float) : `${getCurrencySymbol()} --,--`}</span>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

SubscriptionPlanChoiceForm.defaultProps = {
  versions: []
};

SubscriptionPlanChoiceForm.propTypes = {
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      periods: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          plans: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              price: PropTypes.string,
              price_float: PropTypes.number,
            })
          )
        })
      )
    })
  )
};

export default SubscriptionPlanChoiceForm;
