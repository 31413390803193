import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import {
  resetList,
  setList,
  setPerPage,
  setMethodApi,
} from '../../stores/SearchFilterSubscriptions';
import ItemCard from './ItemCard';

class OtherSubscriptionOffersApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingMore: false,
    };

    setMethodApi('getSubscriptions');
    resetList();
    setPerPage(3);

    setList();

    window.store.SearchFilterSubscriptions.watch((state) => {
      this.setState({
        list: state.list,
      });
    });
  }

  componentDidMount() {
    this.initCarousel();
  }

  initCarousel() {
    new Carousel(this.carousel, {
      slidesPerView: 1.12,
      centerInsufficientSlides: false,
      breakpoints: {
        768: {
          slidesPerView: 3,
          centerInsufficientSlides: true,
        },
      },
      observer: true,
      observeParents: true,
      pagination: {
        el: this.pagination,
        type: 'bullets',
        clickable: true,
      },
    });
  }

  render() {
    const { list } = this.state;
    const { internalPageUrl } = this.props;

    return (
      <div
        ref={(node) => (this.carousel = node)}
        className="other-subscription-offers__list swiper-container"
      >
        <div className="swiper-wrapper">
          {list.map((item, index) => (
            <ItemCard
              title={item.title}
              pitchs={item.sales_pitch}
              thumb={item.avatar.url || item.model.item_image.url}
              price={item.most_basic_period.price_float}
              period={'mês'}
              link={`${internalPageUrl}/${item.slug}`}
              customClassName="swiper-slide"
              key={index}
            />
          ))}
        </div>

        <div className="other-subscription-offers__controls d-lg-none">
          <div
            ref={(node) => (this.pagination = node)}
            className="swiper-pagination other-subscription-offers__slide-pagination"
          ></div>
        </div>
      </div>
    );
  }
}

OtherSubscriptionOffersApp.defaultProps = {
  internalPageUrl: '/',
};
OtherSubscriptionOffersApp.propTypes = {
  internalPageUrl: PropTypes.string,
};

export default OtherSubscriptionOffersApp;
