import { h, Component } from 'preact'
import classNames from 'classnames'
import PropTypes from 'prop-types'

class Dropdown extends Component {
  addEventListeners() {
    window.addEventListener('click', (event) => {
      if (this.ref && event.target !== this.ref && !this.ref.contains(event.target) && this.props.isOpen) {
        this.props.onClose()
      }
    })
  }

  componentDidMount() {
    this.addEventListeners()
  }

  render() {
    const { children, className, isOpen } = this.props

    return (
      <div ref={node => this.ref = node} className={classNames('dropdown-filter', className, {
        'dropdown-filter--open': isOpen
      })}>
        { children }
      </div>
    )
  }
}

Dropdown.defaultProps = {
  isOpen: false,
  onClose() {}
}

Dropdown.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default Dropdown
