import { Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { clearURLSearchParams } from '%/utils/filters';
import Dropdown from './Dropdown';
import DropdownToggle from './DropdownToggle';
import { DropdownMenu, DropdownMenuItem } from './DropdownMenu';
import AdvancedFilterSubscriptions from './AdvancedFilterSubscriptions';
import autoServiceSubscriptionsApi from '../../utils/autoServiceSubscriptionsApi';
import {
  addFilter,
  setList,
  setSort,
  setFilter,
  setKeyword,
} from '../../stores/SearchFilterSubscriptions';
import { getSearchParams } from '../../../portal_assinatura/utils/utils';
import { queryStringify } from '../../utils/queryStringify';

class HeaderFilterSubscriptions extends Component {
  constructor(props) {
    super(props);

    const urlParams = getSearchParams();

    this.state = {
      advancedFilters: false,
      dropdownOrderOpen: false,
      filters: {
        q: urlParams.q,
      },
      filtersOptions: {
        brands: [],
        categories: [],
      },
    };

    this.handleOrderClick = this.handleOrderClick.bind(this);
    this.handleOrderDropClick = this.handleOrderDropClick.bind(this);
    this.handleAdvancedFiltersClick =
      this.handleAdvancedFiltersClick.bind(this);
    this.onSubmitFormSearch = this.onSubmitFormSearch.bind(this);
    this.handleInputKeywordChange = this.handleInputKeywordChange.bind(this);
    this.onOrderMenuItemClick = this.onOrderMenuItemClick.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);

    this.setSelectFilterOptions();
  }

  handleOrderClick(e) {
    e.preventDefault();
    const { dropdownOrderOpen } = this.state;
    this.setState({ dropdownOrderOpen: !dropdownOrderOpen });
  }

  handleOrderDropClick(e) {
    if (e && e.preventDefault) e.preventDefault();
    this.setState({ dropdownOrderOpen: false });
  }

  handleAdvancedFiltersClick(e) {
    e.preventDefault();
    const { advancedFilters } = this.state;
    this.setState({ advancedFilters: !advancedFilters });
  }

  handleInputKeywordChange({ target }) {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...filters,
        q: target.value,
      },
    });
    addFilter({ q: target.value });
  }

  handleClearFilters() {
    this.clearFilter();
  }

  onSubmitFormSearch(e) {
    e.preventDefault();

    const { filter } = window.store.SearchFilterSubscriptions.getState();
    const { internalPage } = this.props;

    if (!internalPage) {
      const filtersStringify = queryStringify(filter);
      window.location = `${this.props.listPageURLRedirect}?${filtersStringify}`;
    } else {
      const url = new URL(window.location.pathname, window.location.origin);
      url.search = queryStringify(filter);

      window.history.pushState(
        '',
        '',
        `${url.origin}${url.pathname}${url.search}`,
      );

      setList();
    }
  }

  onOrderMenuItemClick(e, order) {
    e.preventDefault();

    this.setState({ dropdownOrderOpen: false });
    const { internalPage } = this.props;

    if (!internalPage) {
      window.location = `${this.props.listPageURLRedirect}?order_by=${order}`;
    } else {
      setSort(order);
      setList();
    }
  }

  setSelectFilterOptions() {
    const { filtersOptions } = this.state;

    autoServiceSubscriptionsApi
      .getSubscriptionsFilters()
      .then(({ brands, categories }) => {
        this.setState({
          filtersOptions: {
            ...filtersOptions,
            brands,
            categories,
          },
        });
      });
  }

  clearFilter() {
    this.state.filters = '';
    setFilter({});
    setKeyword('');
    setList().then(() => {
      clearURLSearchParams();
    });
  }

  render() {
    const { advancedFilters, dropdownOrderOpen, filters, filtersOptions } =
      this.state;

    return (
      <form onSubmit={this.onSubmitFormSearch}>
        <div className="row subscriptions-list__header-basic-filters">
          <div className="col-sm-12 col-md-6 mb-3 mb-md-0 subscriptions-list__header-basic-filters-item">
            <div className="input-group">
              <div className="input-group-prepend">
                <button
                  className="btn button button--primary subscriptions-list__header-form-btnsearch"
                  type="submit"
                >
                  <span className="icon icon-search-b" />
                </button>
              </div>
              <input
                type="text"
                className="form-control "
                placeholder="Busca"
                value={filters.q}
                onChange={this.handleInputKeywordChange}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-3 mb-3 mb-sm-0 subscriptions-list__header-basic-filters-item">
            <button
              type="button"
              className={classNames(
                'btn button button--ghost-secondary subscriptions-list__header-form-btn',
                {
                  'subscriptions-list__header-form-btn--open': advancedFilters,
                },
              )}
              onClick={this.handleAdvancedFiltersClick}
            >
              <span
                className={classNames('icon', {
                  'icon-filter': !advancedFilters,
                  'icon-close': advancedFilters,
                })}
              />{' '}
              Filtrar
            </button>
          </div>
          <div className="col-sm-6 col-md-3 subscriptions-list__header-basic-filters-item">
            <Dropdown
              isOpen={dropdownOrderOpen}
              onClose={this.handleOrderDropClick}
            >
              <DropdownToggle
                className="btn button button--ghost-secondary subscriptions-list__header-form-btn"
                isOpen={dropdownOrderOpen}
                toggle={this.handleOrderClick}
              >
                <span
                  className={classNames('icon', {
                    'icon-order': !dropdownOrderOpen,
                    'icon-close': dropdownOrderOpen,
                  })}
                />{' '}
                Ordenar
              </DropdownToggle>
              <DropdownMenu>
                <DropdownMenuItem header>Ordenar ofertas por:</DropdownMenuItem>
                <DropdownMenuItem>
                  <button
                    className="dropdown-filter__list-item-button"
                    type="button"
                    href="#"
                    onClick={e => this.onOrderMenuItemClick(e, 'price asc')}
                  >
                    Menor preço
                  </button>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <button
                    className="dropdown-filter__list-item-button"
                    type="button"
                    href="#"
                    onClick={e => this.onOrderMenuItemClick(e, 'price desc')}
                  >
                    Maior preço
                  </button>
                </DropdownMenuItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        {advancedFilters && (
          <AdvancedFilterSubscriptions
            brands={filtersOptions.brands}
            categories={filtersOptions.categories}
            onClickSubmit={this.onSubmitFormSearch}
            onClickClearFilters={this.handleClearFilters}
          />
        )}
      </form>
    );
  }
}

HeaderFilterSubscriptions.defaultProps = {
  internalPage: false,
  listPageURLRedirect: '',
};

HeaderFilterSubscriptions.propTypes = {
  internalPage: PropTypes.bool,
  listPageURLRedirect: PropTypes.string,
};

export default HeaderFilterSubscriptions;
