import { Component } from 'preact';
import PropTypes from 'prop-types';
import maskField, { MASK_TYPES } from '%/utils/maskField';
import maskText from '%/utils/maskText';
import { addFilter } from '../../stores/SearchFilterSubscriptions';
import { getSearchParams } from '../../../portal_assinatura/utils/utils';
import CustomSelect from '../CustomSelect';

class AdvancedFilterSubscriptions extends Component {
  constructor(props) {
    super(props);

    const urlParams = getSearchParams();

    this.state = {
      filters: {
        category: urlParams.category,
        brand: urlParams.brand,
        'price[gteq]': maskText(
          urlParams['price[gteq]'] || '',
          MASK_TYPES.PRICE_WITHOUT_SEPARATOR,
        ),
        'price[lteq]': maskText(
          urlParams['price[lteq]'] || '',
          MASK_TYPES.PRICE_WITHOUT_SEPARATOR,
        ),
      },
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickClearFilters = this.onClickClearFilters.bind(this);
  }

  componentDidMount() {
    this.configureStaticFieldsMasks();
  }

  handleInputChange({ target }) {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        [target.name]: target.value,
      },
    });

    const filter = target.name;
    let value = target.value;

    if (filter === 'price[gteq]' || filter === 'price[lteq]') {
      value = value.replace(/[^0-9]/g, '');
    }

    addFilter({ [filter]: value });
  }

  onClickSubmit(e) {
    e.preventDefault();

    this.props.onClickSubmit(e);
  }

  onClickClearFilters(e) {
    e.preventDefault();

    this.state = {
      filters: {
        category: '',
        brand: '',
        'price[gteq]': '',
        'price[lteq]': '',
      },
    };

    this.props.onClickClearFilters(e);
  }

  configureStaticFieldsMasks() {
    const fieldsSelectors = {
      '.input-money': MASK_TYPES.PRICE_WITHOUT_SEPARATOR,
    };

    Object.keys(fieldsSelectors).forEach(maskSelector => {
      document.querySelectorAll(maskSelector).forEach(el => {
        maskField(fieldsSelectors[maskSelector], el);
      });
    });
  }

  render() {
    const { brands, categories } = this.props;
    const { filters } = this.state;
    const { 'price[gteq]': priceMin, 'price[lteq]': priceMax } = filters || {};

    const brandsWithValue = brands.map(item => ({
      label: item,
      value: item.toLowerCase(),
    }));

    const categoriesWithValue = categories.map(item => ({
      label: item,
      value: item.toLowerCase(),
    }));

    return (
      <div className="subscriptions-list__header-advanced-filters">
        <div className="row">
          <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0 subscriptions-list__header-advanced-filters-item">
            <div className="form-group">
              <CustomSelect
                placeholderValue="Escolha a Marca"
                classNames={{
                  containerOuter: 'choices choices--subscription-portal',
                }}
                name="brand"
                handleSelectChange={this.handleInputChange}
                options={brandsWithValue}
                value={filters.brand}
                searchEnabled={false}
              />
            </div>
          </div>
          <div className="col-sm-6 col-lg-2 mb-3 mb-lg-0 subscriptions-list__header-advanced-filters-item">
            <div className="form-group">
              <CustomSelect
                placeholderValue="Categoria"
                classNames={{
                  containerOuter: 'choices choices--subscription-portal',
                }}
                name="category"
                handleSelectChange={this.handleInputChange}
                options={categoriesWithValue}
                value={filters.category}
                searchEnabled={false}
              />
            </div>
          </div>
          <div className="col-sm-4 col-lg-2 mb-3 mb-sm-0 subscriptions-list__header-advanced-filters-item">
            <div className="form-group">
              <input
                className="form-control input-money"
                name="price[gteq]"
                placeholder="Preço Mínimo"
                value={priceMin}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-4 col-lg-2 mb-3 mb-sm-0 subscriptions-list__header-advanced-filters-item">
            <div className="form-group">
              <input
                className="form-control input-money"
                name="price[lteq]"
                placeholder="Preço Maximo"
                value={priceMax}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="col-6 col-lg-1 mb-3 subscriptions-list__header-advanced-filters-btn-area btn-area-clear">
            <button
              type="button"
              className="btn button button--block subscriptions-list__header-advanced-filters-btn-clear"
              onClick={this.onClickClearFilters}
            >
              Limpar
            </button>
          </div>
          <div className="col-6 col-lg-2 subscriptions-list__header-advanced-filters-btn-area">
            <button
              type="submit"
              className="btn button button--primary button--block subscriptions-list__header-advanced-filters-btn"
              onClick={this.onClickSubmit}
            >
              Buscar <span className="icon icon-search" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

AdvancedFilterSubscriptions.defaultProps = {
  brands: [],
  categories: [],
  onClickSubmit: () => {},
  onClickClearFilters: () => {},
};

AdvancedFilterSubscriptions.propTypes = {
  brands: PropTypes.arrayOf,
  categories: PropTypes.arrayOf,
  onClickSubmit: PropTypes.func,
  onClickClearFilters: PropTypes.func,
};

export default AdvancedFilterSubscriptions;
