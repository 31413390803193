import {h, Component} from 'preact'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {formatPrice} from "../../../portal_assinatura/utils/utils"

class ItemCard extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    const {
      title,
      thumb,
      link,
      pitchs,
      price,
      period,
      customClassName,
    } = this.props;

    return (
      <div
        className={classNames('subscription-card', customClassName)}
        data-match-height="subscriptions-list-item"
      >
        <header className="subscription-card__header">
          <h3 className="subscription-card__title">{title}</h3>
          <div className="subscription-card__thumb">
            <a href={link}>
              <img
                src={thumb}
                alt="Foto do Modelo para Assinatura"
                title={title}
              />
            </a>
          </div>
        </header>
        <div className="subscription-card__body">
          <ul className="subscription-card__advantages">
            {(pitchs || []).map((item) => (
              <li>{item}</li>
            ))}
          </ul>
          <h4 className="subscription-card__plan">
            <span>A partir de </span>
            <strong>
              {formatPrice(price)} / {period}
            </strong>
          </h4>
          <a
            href={link}
            className="btn button button--primary button--block subscription-card__btn"
          >
            Conheça o Veículo
          </a>
        </div>
      </div>
    );
  }
}

ItemCard.defaultProps = {
  title: "",
  thumb: "",
  link: "",
  pitchs: [],
  price: 0,
  period: "",
  customClassName: "",
}

ItemCard.propTypes = {
  title: PropTypes.string,
  thumb: PropTypes.string,
  link: PropTypes.string,
  pitchs: PropTypes.array,
  price: PropTypes.number,
  period: PropTypes.string,
  customClassName: PropTypes.string,
};

export default ItemCard
